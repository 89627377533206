import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './FooterNav.css';
import DownloadDialog from './DownloadDialog';

const FooterBrand = () => {
  return (
    <Link to="/" className="footer-brand-name">
      <img src={process.env.PUBLIC_URL + '/images/white_logo.png'} alt="" />
      Tame Tummy
    </Link>
  );
}

const FooterNav = () => {
  const [isDialogVisible, setDialogVisible] = useState(false);

  return (
    <nav className="footer-navbar">
      <FooterBrand />
      <div className="footer-links">
        <ul className="footer-nav-links">
          <li><Link to="/articles">Articles</Link></li>
          {/* <li><Link to="/about">About</Link></li> */}
          {/* <li><Link to="/contact">Contact</Link></li> */}
        </ul>
        <button className="footer-nav-button" onClick={() => setDialogVisible(true)}>Download Now</button>
        <DownloadDialog 
            isVisible={isDialogVisible} 
            onClose={() => setDialogVisible(false)} 
        />
      </div>
    </nav>
  );
}

export default FooterNav;
