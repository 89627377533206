import React from 'react';
import ArticleItem from './ArticleItem';
import './HomeBlog.css';
import { useNavigate } from 'react-router-dom';
import ArticlesData from '../json/ArticlesData';

const HomeBlog = () => {
  const navigate = useNavigate();

  const handleArticleClick = (id) => {
      navigate(`/articles/${id}`);
  };

  const handleReadMoreClick = () => {
      navigate('/articles');
  };

  const lastThreeArticles = ArticlesData.slice(-3); 

  return (
    <div className="blog-container">
      <h1 className="blog-title">Read our latest articles</h1>
      <div className="blog-posts-container">
        <div className="blog-posts">
          <ArticleItem
            id={lastThreeArticles[0]?.id}
            image={lastThreeArticles[0]?.image}
            title={lastThreeArticles[0]?.title}
            desc={lastThreeArticles[0]?.desc}
            onClick={handleArticleClick}
            featured={true}
          />
          <div className="stacked-blog-posts">
            {lastThreeArticles.slice(1).map((article, index) => (
              <ArticleItem
                key={index}
                id={article.id}
                image={article.image}
                title={article.title}
                desc={article.desc}
                onClick={handleArticleClick}
              />
            ))}
          </div>
        </div>
      </div>
      <button className="read-more-btn" onClick={handleReadMoreClick}>
        Read More
      </button>
    </div>
  );
};

export default HomeBlog;
