import React from 'react';
import './Export.css';

const ExportText = () => {
    return (
        <div className="export-text-container">
            <h1 className="export-title">Export your data</h1>
            <p className="export-subtext">Create a PDF report in seconds that will help give your doctor a complete picture of your progress and overall gut health.</p>
        </div>
    )
}

const ExportScreenshot = () => {
    return (
        <img 
            src={process.env.PUBLIC_URL + '/images/PDF.png'} 
            alt=''
            className="export-screenshot"
        />     
    )
}

const Export = () => {
    return (
        <div className='export-bg'>
            <ExportText />
            <ExportScreenshot />
        </div>
    )
};

export default Export;
