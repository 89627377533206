import React from 'react';
import './Banner.css';

const BannerHeadlineText = () => {
    return (
        <div className="banner-headline-text-container">
            <h1 className="banner-headline-title">Take back control of your gut health with Tame Tummy</h1>
            <p className="banner-headline-subtext">Millions of people suffer from gut problems every day. You don't have to fight it alone. Tame Tummy simplifies tracking and gives you a clearer picture of your gut health. Try it today!</p>
            <a href="https://apps.apple.com/us/app/tame-tummy/id6446749964" target="_blank" rel="noopener noreferrer">
                <img 
                    src={process.env.PUBLIC_URL + '/images/ios-store.svg'} 
                    className="store-link"
                    alt=""
                />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.tametummy.app"target="_blank" rel="noopener noreferrer">
                <img 
                    src={process.env.PUBLIC_URL + '/images/android-store.svg'} 
                    className="store-link"
                    alt=""
                />
            </a>
        </div>
    )
}


const BannerHeadlineAppScreenshot = () => {
    return (
        <div className="banner-headline-app-screenshot">
            <img 
                src={process.env.PUBLIC_URL + '/images/app_banner_screenshot.png'} 
                alt=''
                className="app-screenshot-banner"
            />            
            <img 
                src={process.env.PUBLIC_URL + '/images/food_bowl.png'} 
                alt=''
                className="food-bowl"
            />
        </div>
    )
}

const BannerBackground = () => {
    return (
        <div className='banner-bg'>
            <img 
                src={process.env.PUBLIC_URL + '/images/food_bg_pattern.svg'} 
                alt=''
                className="banner-bg-image"
            />
            <BannerHeadlineText />
            <BannerHeadlineAppScreenshot />
        </div>
    )
}

const Banner = () => {
    return (
        <BannerBackground />
    )
};

export default Banner;
