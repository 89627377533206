import React, { useEffect } from 'react';
import Features from '../component/Features.js';
import Banner from '../component/Banner.js';
import Trends from '../component/Trends.js';
import Export from '../component/Export.js';
import HomeBlog from '../component/HomeBlog.js';

{/* test */}
const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return (
        <div>
            <Banner />
            <Features />
            <Trends />
            <Export />
            <HomeBlog />
        </div>
    );
}

export default Home;
