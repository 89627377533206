import React from 'react';
import './Trends.css';

const TrendsText = () => {
    return (
        <div className="trends-text-container">
            <h1 className="trends-title">Use analytics to discover insights</h1>
            <p className="trends-subtext">Delve deeper into your gut health journey with analytics. Visualize your data to discover trends and insights with your diet and symptoms. Your path to wellness just got clearer.</p>
        </div>
    )
}

const TrendsScreenshot = () => {
    return (
        <div className="trends-screenshot">
            <img 
                src={process.env.PUBLIC_URL + '/images/analytics_shell.png'} 
                alt=''
                className="trends-shell"
            />            
            <img 
                src={process.env.PUBLIC_URL + '/images/analytics_chart.png'} 
                alt=''
                className="trends-chart"
            />
        </div>
    )
}

const Trends = () => {
    return (
        <div className="trends-container">
            <div className='trends-bg'>
                <TrendsScreenshot />
                <TrendsText />
            </div>
        </div>
    )
};

export default Trends;
