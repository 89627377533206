import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Nav.css';
import DownloadDialog from './DownloadDialog';

const Brand = () => {
  return (
    <Link to="/" className="brand-name">
      <img src={process.env.PUBLIC_URL + '/images/logo_primary.svg'} alt="Tame Tummy Logo" />
      Tame Tummy
    </Link>
  );
}

const Nav = () => {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isDialogVisible, setDialogVisible] = useState(false);

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <nav className="navbar">
      <Brand />
      <div className="menu-icon" onClick={toggleNav}>
        <i className={isNavVisible ? 'icon active' : 'icon'}>&#9776;</i>
      </div>
      <div className={`links ${isNavVisible ? 'show' : ''}`}>
        <ul className="nav-links">
          <li><Link to="/articles" onClick={toggleNav}>Articles</Link></li>
          {/* <li><Link to="/about" onClick={toggleNav}>About</Link></li> */}
          {/* <li><Link to="/contact" onClick={toggleNav}>Contact</Link></li> */}
        </ul>
        <button className="nav-button"  onClick={() => setDialogVisible(true)}>Download Now</button>
        <DownloadDialog 
            isVisible={isDialogVisible} 
            onClose={() => setDialogVisible(false)} 
        />
      </div>
    </nav>
  );
}

export default Nav;
