import React from 'react';
import './Features.css';

const MealIcon = () => {
    return (
        <div className="box" style={{backgroundColor: '#E6FAF8'}}>
            <img 
                src={process.env.PUBLIC_URL + '/images/meal_icon.svg'} 
                alt=''
                className="feature-icon"
            />
        </div>
    )
}

const MedicineIcon = () => {
    return (
        <div className="box" style={{backgroundColor: '#EBF2FF'}}>
            <img 
                src={process.env.PUBLIC_URL + '/images/medicine_icon.svg'} 
                alt=''
                className="feature-icon"
            />
        </div>
    )
}

const SymptomIcon = () => {
    return (
        <div className="box" style={{backgroundColor: '#FFF2F2'}}>
            <img 
                src={process.env.PUBLIC_URL + '/images/symptom_icon.svg'} 
                alt=''
                className="feature-icon"
            />
        </div>
    )
}

const Diet = () => {
    return (
        <div className='func-stack'>
            <MealIcon />
            <h2 className='stack-title'>Track your diet</h2>
            <p className='stack-desc'>Keeping track of what goes into your tummy, made easy. Track food items, portion sizes, breakfast through dinner and everything in between.</p>
        </div>
    )
}

const Medicine = () => {
    return (
        <div className='func-stack'>
            <MedicineIcon />
            <h2 className='stack-title'>Track your medicine</h2>
            <p className='stack-desc'>Treating your symptoms with medication can be complicated. Tracking them shouldn't be. Easily track your medication and dosage.</p>
        </div>
    )
}

const Other = () => {
    return (
        <div className='func-stack'>
            <SymptomIcon />
            <h2 className='stack-title'>Track your symptoms & stress</h2>
            <p className='stack-desc'>Whatever is bothering you, we've got you covered. Customize the type and severity of your symptoms, stress, and more.</p>
        </div>
    )
}

const Features = () => {
    return (
        <div className="app-functions">
            <h1 className='title'>What makes Tame Tummy useful for your gut health</h1>
            <ul>
                <li><Diet /></li>
                <li><Medicine /></li>
                <li><Other /></li>
            </ul>
        </div>
    );
}

export default Features;