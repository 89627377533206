import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Article.css';
import ArticlesData from '../json/ArticlesData';

const Article = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { articleId } = useParams();
  const article = ArticlesData.find((article) => article.id.toString() === articleId);

  if (!article) {
    return <div>Article not found</div>;
  }

  return (
    <div className="article-content">
      <div className="article-header">
        <h1>{article.title}</h1>
        <img src={article.image} alt={article.title} />
      </div>
      <div className="article-body">
        {article.content}
        <h3>Important Note:</h3>
        <p>This article is intended for informational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.</p>    
      </div>
    </div>
  );
};

export default Article;
