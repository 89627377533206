import React from 'react';
import './ArticleItem.css';
import { Link } from 'react-router-dom';

function ArticleItem({ id, image, title, desc, featured }) {
  const articleClass = featured ? 'featured-article-item' : 'article-item';

  return (
    <Link to={`/articles/${id}`} className={articleClass}>
        <img src={process.env.PUBLIC_URL + image} alt={title} />
        <h2>{title}</h2>
        <p>{desc}</p>
    </Link>
  );
}

export default ArticleItem;
