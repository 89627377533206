import React from 'react';
import ReactDOM from 'react-dom';
import './DownloadDialog.css';

const DownloadDialog = ({ isVisible, onClose }) => {
    if (!isVisible) return null;

    const dialog = (
        <div className="download-dialog-overlay" onClick={onClose}>
            <div className="download-dialog-container" onClick={(e) => e.stopPropagation()}>
                <img 
                    src={process.env.PUBLIC_URL + '/images/cross.svg'}
                    alt="Close"
                    className="close-button"
                    onClick={onClose}
                />
                <h1 className="dialog-title">Download Tame Tummy!</h1>
                <p className="dialog-subtext">Join the community reclaiming their gut health. Instant access to tracking and insights tailored for you. Begin your journey to a happier, healthier gut today!</p>
                <div className="store-links">
                    <a href="https://apps.apple.com/us/app/tame-tummy/id6446749964" target="_blank" rel="noopener noreferrer">
                        <img 
                            src={process.env.PUBLIC_URL + '/images/ios-store.svg'} 
                            alt="Download from the Apple App Store"
                        />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.tametummy.app" target="_blank" rel="noopener noreferrer">
                        <img 
                            src={process.env.PUBLIC_URL + '/images/android-store.svg'} 
                            alt="Download from the Google Play Store"
                        />
                    </a>
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        dialog,
        document.body
    );
}

export default DownloadDialog;

