import React, { useEffect } from 'react';
import ArticleItem from '../component/ArticleItem';
import { useNavigate } from 'react-router-dom';
import '../component/HomeBlog.css';
import ArticlesData from '../json/ArticlesData';

function Articles() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleArticleClick = (id) => {
    navigate(`/articles/${id}`);
  };

  const reversedArticlesData = [...ArticlesData].reverse();

  return (
    <div className="blog-container">
      <h1 className="blog-title">All Articles</h1>
      <div className="blog-posts-container">
        <div className="blog-posts">
          <ArticleItem
            id={reversedArticlesData[0]?.id}
            image={reversedArticlesData[0]?.image}
            title={reversedArticlesData[0]?.title}
            desc={reversedArticlesData[0]?.desc}
            onClick={handleArticleClick}
            featured={true}
          />
          <div className="stacked-blog-posts">
            {reversedArticlesData.slice(1, 3).map((article) => (
              <ArticleItem
                key={article.id}
                id={article.id}
                image={article.image}
                title={article.title}
                desc={article.desc}
                onClick={handleArticleClick}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="grid-container">
        {reversedArticlesData.slice(3).map((article) => (
          <ArticleItem
            key={article.id}
            id={article.id}
            image={article.image}
            title={article.title}
            desc={article.desc}
            onClick={handleArticleClick}
          />
        ))}
      </div>
    </div>
  );
}

export default Articles;
