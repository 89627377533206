import React from 'react';
import './Footer.css';
import FooterNav from './FooterNav';

const FooterBannerContent = () => {
    return (
        <div className="footer-banner-text-container">
            <h1 className="footer-banner-title">Gut problems? Tame Tummy is here to help!</h1>
            <p className="footer-banner-subtext">Millions of people suffer from gut problems every day. You don't have to fight it alone. Tame Tummy simplifies tracking and gives you a clearer picture of your gut health. Try it today!</p>
            <a href="https://apps.apple.com/us/app/tame-tummy/id6446749964" target="_blank" rel="noopener noreferrer">
                <img 
                    src={process.env.PUBLIC_URL + '/images/ios-store.svg'} 
                    className="footer-store-link"
                    alt=""
                />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.tametummy.app"target="_blank" rel="noopener noreferrer">
                <img 
                    src={process.env.PUBLIC_URL + '/images/android-store.svg'} 
                    className="footer-store-link"
                    alt=""
                />
            </a>
        </div>
    )
}

const FooterBannerImage = () => {
    return (
        <div className="footer-image-container">
            <img 
                src={process.env.PUBLIC_URL + '/images/footer_image_iphone.png'} 
                alt=''
                className="footer-image-iphone"
            />            
            <img 
                src={process.env.PUBLIC_URL + '/images/footer_image_content.png'} 
                alt=''
                className="footer-image-content"
            />
        </div>
    )
}

const Footer = () => {
    return (
        <div>
            <div className="footer">
                <div className="footer-container">
                    <FooterBannerContent />
                    <FooterBannerImage />
                </div>
            </div>
            <FooterNav />
            <div className="footer-copyright">
                &copy; {new Date().getFullYear()} Tame Tummy. All rights reserved.
            </div>
        </div>
    );
}

export default Footer;
