import React from 'react';
import './App.css';
import Nav from './component/Nav';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './component/Footer';
import Articles from './pages/Articles';
import Article from './pages/Article';

const About = () => <div>About Page</div>;
const Contact = () => <div>Contact Page</div>;

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/articles" element={<Articles />} />
            {/* <Route path="/about" element={<About />} /> */}
            {/* <Route path="/contact" element={<Contact />} /> */}
            <Route path="/articles/:articleId" element={<Article />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
